// Styles
import 'vuetify/styles';
// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { aliases, mdi } from 'vuetify/iconsets/mdi'

import * as labs from 'vuetify/labs/components'

const vuetify = createVuetify({
    components: {
        ...labs,
        ...components,
        ...directives

    },
    breakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1400,
        },
    },
    rtl: false,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        options: {
            customProperties: true
        },
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: 'var(--company-primary)',
                    secondary: '#403E48',
                    bluecool200: '#D9D8DA',
                    fireaxered100: '#FBFAFB',
                    neutral100: '#F5F5F5',
                },
            },
            dark: {
                colors: {
                    primary: '#F24139',
                    secondary: '#403E48',
                    bluecool200: '#D9D8DA',
                    fireaxered100: '#FBFAFB',
                    neutral100: '#F5F5F5',
                },
            },

        },

    }
});

export default vuetify;